<template>
  <div class="mobile-container">
    <userHeader custom-active="2"></userHeader>
    <div class="list-container">
      <div class="tools">
        <div class="result">{{ data.length }} {{ $t("lang.results") }}</div>
        <!-- <div class="sort" @click="networkBy">
          <span>{{ networkVos[network].fullName }}</span>
          <i class="el-icon-arrow-down"></i>
        </div> -->
        <div class="sort" @click="sortBy">
          <span>{{ sortList[sort].name }}</span>
          <i class="el-icon-arrow-down"></i>
        </div>
      </div>
      <ul class="list-wrapper">
        <li class="item" v-for="(item, index) in data" :key="'item_' + index">
          <div class="item-thumbnail">
            <img  class="img"  @click="toDetail(item.id)"   :src="item.url" />
          </div>
          <div class="item-info">
            <h4>
              <span class="bold">{{ item.title }}</span>
              <span class="gray">{{$t("lang.BoundValue")}}</span>
            </h4>
            <p class="row">
              <span class="owner">{{  item.owner_name  }}</span>
              <span class="bold"  ><img src="@/assets/img/icon.png" alt="" />{{   item.amount  }}</span  >
            </p>
            <div class="hr"></div>
            <ul class="price-btn">
              <li>
                <!-- 价格 --> 
                <div class="sell-price">
                  <span>{{$t("lang.sellPrice")}}</span>
                  <p>
                    <img src="@/assets/img/icon.png" alt="" />
                    <span>{{ item.price }}</span>
                  </p>
                </div>
              </li>
              <li>
                <span  class="btns" @click="handleClick(item)"  :class="{ cancel: item.isSame }" v-if="item.isSame" >{{$t("lang.cancel")}}</span  >
                <span  class="btns" @click="handleClick(item)"  :class="{ cancel: item.isSame }" v-else>{{$t("lang.Buy")}}</span  >
              </li>
            </ul>
          </div>
        </li>
        <li v-if="data == null || data.length == 0" class="noData">
          <img :src="require('@/assets/img/nodata_market.png')" class="img" />
        </li>
      </ul>
    </div>
    <Footer></Footer>
    <select-popover  @close="handleClose"  @change="handleChange"  :show="visible"  :List="sortList"  :active="sort"  size="200px"  :name="true"  ></select-popover>
    <select-picker  @close="networkClose"  @change="networkChange"  :show="networkVisible"  :List="networkVos"  :active="network"  size="200px" :fullName="true" ></select-picker>
    <!-- 取消销售 -->
    <Cancel  :ShowTooltip="ShowCancel"   :TradeId="TradeId"  @closeTooltip="closeTooltip"></Cancel>
    <connectMetamask ref="showMetamask"></connectMetamask>
    <el-dialog   :visible="showWord" :title='$t("lang.PasswordVerification")'  :close-on-click-modal="false"  width="85%" custom-class="mint-dialog"  @close="closeWord"  >
      <div class="left-img" v-if="itemWord.url">
        <img :src="itemWord.url" />
      </div>
      <ul>
        <li><span>Title：</span>{{ itemWord.title }}</li>
        <li>
          <span>{{ $t("lang.value") }}:</span>
          <p>
            <img src="./../../assets/img/icon.png" alt="" />
            <span>{{ itemWord.price }}</span>
          </p>
        </li>
      </ul>
      <el-input :placeholder="$t('lang.PleasePassword')" v-model="password" show-password></el-input>
      <div class="mint-Confirm">
        <el-button @click="handleBuy()" :loading="btnLoadings" round   >{{ $t("lang.Purchase")}}</el-button >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Header from "@/components/mobile/header";
import userHeader from "@/components/mobile/userHeader";
import Footer from "@/components/mobile/footer";
import request from "../../services/request";
import {BASE_V_API} from "@/config.js"
import {BASE_GLOB_API} from "@/config.js";
import {axiosGet} from '@/api/api';
import ad from "../../utils/web3Helper.js";
import selectPopover from "@/components/mobile/select-popover.vue";
import selectPicker from "@/components/mobile/select-picker.vue";
import Cancel from "@/components/mobile/cancel.vue";
import market from "@/utils/market";
import { mapState } from "vuex";
export default {
  name: "tradingMarket-m",
  data() {
    return {
      data: [],
      sortList: [
        {
          name: this.$t("lang.Recently"),
          id: 5,
        },
        {
          name: this.$t("lang.price")+":"+this.$t("lang.LowToHigh"),
          id: 1,
        },
        {
          name: this.$t("lang.price")+":"+this.$t("lang.HighToLow"),
          id: 2,
        },
        {
          name:this.$t("lang.BoundValue")+":"+this.$t("lang.LowToHigh"),
          id: 3,
        },
        {
          name:this.$t("lang.BoundValue")+":"+this.$t("lang.HighToLow"),
          id: 4,
        },
      ],
      networkVos:[],
      base: "",
      sort: 0,
      network:0,
      visible: false,
      networkVisible: false,
      loading: false,
      ShowCancel: false,
      TradeId: null,
      showWord:false,
      itemWord:{},
      password:'',
      btnLoadings:false,
      url:{
        platform:BASE_GLOB_API+'/kanban/coin/platform',
        },
    };
  },
  components: {
    Header,
    userHeader,
    Footer,
    selectPopover,
    selectPicker,
    Cancel,
  },
  created() {
    this.loadData();
    // this.platform()
  },
  computed: {
    ...mapState(["address"]),
  },
  methods: {
    closeWord(){
      this.showWord =false
      this.password =''
    },
    networkClose() {
      this.networkVisible = false;
    },
    networkChange(i) {
      this.network = i;
      // this.loadData();
      this.networkVisible = false;
    },
    networkBy() {
      this.networkVisible = true;
    },
    platform(){
        axiosGet(this.url.platform).then(res=>{
            if(res.code ==200){
                this.networkVos =res.result
                // this.networkVos =[{fullName:"eth",id:1,enabled:true},{fullName:"tth",id:2,enabled:false},{fullName:"lth",id:3,enabled:false}]
            }else{
                this.$message.error(res.message);
            }
        })
        
    }, 
    //关闭弹窗
    closeTooltip() {
      this.ShowCancel = false;
      this.loadData();
    },
    handleBuy(){
          if (this.password.length == 0) {
            this.$message.error(this.$t("lang.PleasePassword"));
            return;
          }
          let that = this;
            const para = {     
                nft_id: this.itemWord.id,
                payPassword:this.password
            };
            that.btnLoadings =true
          request.marketBuy(para).then((res) => {
            if (res.code == "200") {
              this.password =''
              that.btnLoadings =false
              that.loadData();
            }else {
              this.password =''
              that.btnLoadings =false
              that.$message.error(res.message);
            }
          }).catch((err) => {
            this.password =''
            that.btnLoadings =false
            that.$message.error(err.message);
          });
    },
    handleClick(item) {//是否自己出售
      // let token = this.getStorageToken();
      // if (this.address == "") {
      //   this.$refs.showMetamask.show = true;
      // } else 
      let token = localStorage.getItem("Token");
      if (!token) {
        sessionStorage.setItem("iSsignature", false);
        this.getSign();
        this.$router.push({name:'login-m'});
        // this.$router.push({name:'login-m',query:{redirect:window.vm.$router.currentRoute.fullPath}});
      } else {
        if (item.isSame) {
          //isSame 是自己的就取消出售
          this.TradeId = item.id;
          this.ShowCancel = true;
        } else {
           //不是自己的就购买
           this.itemWord = item
           this.showWord =true
          
         
          //  this.showLoading()
          // market.buy(
          //   item.TradeId,
          //   item.price,
          //   (result) => { 
          //     this.loading.close();
          //     setTimeout(() => {
          //       location.reload();
          //     }, 3000);
          //   },
          //   (err) => {
          //     this.loading.close();
          //     console.log(err);
          //   }
          // );
        }
      }
    },
    handleClose() {
      this.visible = false;
    },
    handleChange(i) {
      this.sort = i;
      this.loadData();
      this.visible = false;
    },
    sortBy() {
      this.visible = true;
    },
    toDetail(tokenID) {
      this.$router.push({ name: "nftDetail-m", query: { tokenID: tokenID } });
    },
    loadData() {
      // this.showLoading()
      this.base = BASE_V_API;
       let userInfo = JSON.parse(sessionStorage.getItem("userinfo"));
      const params = {
        key:  "",
        page: 1,
        page_size: 100, 
        typa: 1,
        sort: this.sortList[this.sort].id,
      };
      request.getMarket(params).then((response) => {
        if (response.code == 200) {
          // this.loading.close();
          this.data = response.data || [];
          this.data.forEach((item, index) => {
            // this.data[index].price = ad.fromWei(this.data[index].price);
            // this.data[index].amount = ad.fromWei(this.data[index].amount);
            if (String(item.out_user_id) == String(userInfo.id)) {
              item.isSame = true;
            } else {
              item.isSame = false;
            }
          });
         
        }
      });
    },
    showLoading() {
      this.loading = this.$loading({
        lock: true,
        text: this.$t("lang.please"),
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.6)",
      });
    },
  },
};
</script>
<style lang="less" scoped>
.mint-dialog {
  .left-img {
    text-align: center;
    img {
      max-height: 300px;
    }
  }
  ul {
    li {
      display: flex;
      align-items: center;
      line-height: 40px;
      & > span {
        color: #000;
        font-weight: 600;
        margin-right: 5px;
      }
      img {
        width: 22px;
      }
    }
  }
  .mint-Confirm {
    text-align: center;
    margin-top: 10px;
    .el-button {
      background: #ffdda9;
      border: none;
      &:hover {
        border: none;
        color: #333;
        background: #fad398;
      }
    }
  }
}
.list-container {
  min-height: calc(100vh - 260px);
  .tools {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 20px 15px;
    .filters {
    }
    .settings {
      .icon {
        width: 24px;
        height: 24px;
      }
    }
  }
}
.sort {
  padding: 6px 9px;
  border-radius: 4px;
  border: 1px solid #ccc;
  span {
    margin-right: 5px;
    color: #666;
  }
}
.list-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 0 5px 20px;
  .item {
    width: 48%;
    .item-thumbnail {
      position: relative;
      width: 100%;
      height: 261px;
      box-sizing: border-box;
      .img {
        display: block;
        border-radius: 2px;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .trunk-num {
        position: absolute;
        font-size: 12px;
        color: #841ae9;
        z-index: 1;
        right: 4px;
        bottom: 1px;
        text-align: right;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 45%;
        line-height: 22px;
      }
    }
    .item-info {
      font-size: 12px;
      color: #666;
      h4 {
        font-weight: 400;
        .bold,
          .gray {
            display: inline-block;
            // width: 50%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
      }
      .bold {
        font-size: 13px;
        color: #000;
        font-weight: 700;
        display: flex;
        align-items: center;
        img {
          width: 20px;
        }
      }
      .row,
      h4 {
        line-height: 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .hr {
        margin: 6px 0;
        height: 1px;
        width: 100%;
        background: #ebebeb;
      }
      .price-btn {
        display: flex;
        align-items: center;
        justify-content: space-between;
        li {
          line-height: 30px;
        }
        .sell-price {
          display: flex;
          font-size: 12px;
          align-items: center;
          margin-bottom: 2px;
          span {
            color: #333333;
          }
          p {
            display: flex;
            align-items: center;
            img {
              width: 16px;
            }
            span {
              font-size: 14px;
              color: #264bae;
              font-weight: 700;
            }
          }
        }
        .btns {
          background: #ffdda9;
          color: #12022f;
          padding: 4px 6px;
        }
        .cancel {
          background: #999;
          color: #fff;
        }
      }
    }
  }

  .noData {
    width: 100%;
    height: calc(100vh - 350px);
    text-align: center;
    img {
      width: 50%;
    }
  }
}

.btn {
  display: inline-block;
  border: #ccc 1px solid;
  font-size: 16px;
  line-height: 26px;
  padding: 8px 20px;
  border-radius: 4px;
  color: #000;
  text-align: center;
  min-width: 130px;
  &.btn-rounded {
    border-radius: 10em;
  }
  &.btn-nft {
    background: #ffdda9;
    border-color: #ffdda9;
    color: #000;
  }
}
.mint-Confirm {
    text-align: center;
    margin-top: 10px;
    .el-button {
      background: #ffdda9;
      border: none;
      &:hover {
        border: none;
        color: #333;
        background: #fad398;
      }
    }
  }
</style>
<style>
.el-loading-spinner i {
  color: #dadce6;
  font-size: 30px;
}
.el-loading-spinner .el-loading-text {
  color: #dadce6;
  margin-top: 5px;
}
</style>